<template>
  <div class="intermediaryGuarantee">
    <div class="title">
      <router-link :to="{name:'Home'}">首页</router-link>
      <router-link :to="{name:'IntermediaryGuarantee'}">&emsp;<span class="Breadcrumb"><i
        class="el-icon-arrow-right "></i></span>&emsp;中介担保
      </router-link>
    </div>
    <div class="content">
      <el-image :src="require('@/assets/image/TradeTip/intermediary-head.png')"></el-image>
      <div class="item">
        <div style="text-align: center">
          <el-image :src="require('@/assets/image/TradeTip/otherDescription.png')"
                    style="width: 318px;margin-top:40px;margin-bottom: 30px"></el-image>
        </div>
        <div class="otherDescription">
          <div class="item">
            买家付账号全款以后，上号验号，与卖家描述相符即可过户账号资料。<br/>
            根据监管单位规定，平台会默认收取卖家身份证信息并验证真假，<span>如不能提供请提前告知客服</span>。
          </div>
          <div class="item">
            <span>任意方取消交易，中介手续费不退。</span>
          </div>
          <div class="item">
            <span>账号发生找回联系无果补偿号价10%(上限15000)，</span>可依法按规提供交易凭证、配合买家报警或起诉，建议购买包赔服务。
          </div>
          <div class="item">
            <span>注：</span>个别游戏中介无售后和赔付，详情请在交易前咨询客服，以客服答复为准。
          </div>
        </div>
      </div>
      <div class="item">
        <div style="text-align: center">
          <el-image :src="require('@/assets/image/TradeTip/charge.png')"
                    style="width: 318px;margin-top:40px;margin-bottom: 30px"></el-image>
        </div>
        <!--  其他游戏  -->
        <div style="cursor: pointer"
             @click="goToCus(`https://tb.53kf.com/code/client/1d02d00edbb13878088ba0b015676b7e9/1${Math.floor(Math.random()*10+1)}`)">
          <el-image :src="require('@/assets/image/TradeTip/other.png')" style=""></el-image>

        </div>
        <div class="game-list">
          <div class="game-item" v-for="(item,index) in gameList" :key="index" @click="goToCus(item.cus)">
            <div class="game-info">
              <el-image class="game-img" :src="item.img"></el-image>
              <div class="title">
                <div>{{ item.title1 }}</div>
                <div>{{ item.title2 }}</div>
              </div>
            </div>
            <div class="game-cus" >
              <div>{{ item.gameName }}</div>
              <div style="cursor: pointer">联系在线客服<i class="el-icon-arrow-right"></i></div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "intermediaryGuarantee",
  data() {
    return {
      gameList: [
        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001023208_034052.png',
          gameName: '原神',
          title1: '号价2%（最低10）',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/7'
        },
        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001024257_797181.png',
          gameName: '王者荣耀',
          title1: '手续费固定10元，首保10%，续保10%',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/5'
        },
        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001022953_568399.png',
          gameName: '英雄联盟',
          title1: '手续费固定10元，首保10%，续保10%',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/10'
        },
        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001021254_825941.png',
          gameName: '天刀端游',
          title1: '号价2%（最低30上限1000）',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/2'
        },
        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001022112_462644.png',
          gameName: '和平精英',
          title1: '手续费固定10元，首保10%，续保10%',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/5'
        },
        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001021952_847049.png',
          gameName: '剑灵',
          title1: '号价2%（最低30）',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/3'
        },
        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001024209_495652.png',
          gameName: 'QQ三国',
          title1: '手续费固定10元，首保10%，续保8%（三国普通中介无售后）',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/4'
        },
        {
          img: 'https://images.pxb7.com/images/game/20211001/20211001023336_673717.png',
          gameName: 'DNF-地下城与勇士',
          title1: '手续费固定10元，首保10%，续保10%',
          cus: 'https://tb.53kf.com/code/client/528d4f3bc25d9279010007a8c01daf5a4/5'
        },






      ]
    }
  },
  methods: {
    goToCus(url) {
      window.open(url)
    }
  }
}
</script>

<style scoped lang="less">

.intermediaryGuarantee {
  width: 1200px;

  .title {
    height: 60px;
    display: flex;
    margin-left: 5px;
    align-items: center;

    a:first-of-type {
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
    }

    a:last-of-type {
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #FCAD13;
    }

    a {
      .Breadcrumb {
        font-size: 12px;
      }
    }
  }

  .content {
    background: #FFFFFF;
    padding: 0 0 63px;
    .item{
      padding: 0 160px;
      .otherDescription {
        font-size: 16px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #5B5B5B;
        line-height: 22px;

        .item {
          margin-bottom: 13px;
          padding-left: 14px;
          position: relative;

          &::after {
            content: '';
            width: 8px;
            height: 8px;
            background-color: rgba(91, 91, 91, 0.95);
            position: absolute;
            top: 6px;
            left: 0;
            border-radius: 2px;
          }

          span {
            font-weight: bold;
          }
        }
      }
      .game-list {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .game-item {
          width: 380px;
          background: #FFFFFF;
          box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.19);
          border-radius: 10px;
          margin-bottom: 20px;
          padding: 20px 20px 0 20px;
          cursor: pointer;
          .game-info {
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px solid rgba(151, 151, 151, 0.15);

            .game-img {
              min-width: 60px;
              max-width: 60px;
              margin-right: 20px;
              border-radius: 12px;
            }

            .title {
              font-size: 16px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #5B5B5B;
              line-height: 22px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
            }
          }

          .game-cus {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 8px 0 11px 0;
            font-size: 14px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #5B5B5B;
            line-height: 20px;
          }
        }
      }

      .hint {
        font-size: 16px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #A3B2C4;
        line-height: 22px;
        text-align: center;
      }
    }
  }
}

</style>
